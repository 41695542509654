import gql from 'graphql-tag'
import { filter } from 'graphql-anywhere'
import { DocumentNode } from 'graphql'

/**
 * Strips unnecessary fields from data in order to successfully perform GraphQL mutations.
 * @param data - Data to clean.
 * @param fragment - Fragment GraphQL as returned by graphql-tag.
 */
export default function cleanData<T> (data : T, fragment : DocumentNode) : T {
  return filter(fragment, data)
}
